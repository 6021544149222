<template>
  <!-- View project template modal template -->
  <b-modal ref="view-io" hide-footer @hide="hideIOModal">
    <div class="d-block text-center">
      <h4 qid="update-tp-title">
        {{ $t('generic_controls.projects.view.title') }}
      </h4>
    </div>
    <div class="d-block text-center mb-3" v-if="object.project">
      <h5 qid="update-tp-title">
        {{ object.project.title }}
      </h5>
    </div>
    <b-alert v-if="error.length"
             show
             variant="danger"
             qid="create-p-error"
    >
      {{ error }}
    </b-alert>

    <div v-if="object.project && object.project.active_client && object.project.active_client.parent">
      <label class="mt-3">
        {{ $t('generic_controls.projects.table.partition') }}
      </label>
      <br>
      <span v-if="object.project" qid="io-name">
        {{ object.project.active_client.name }}
      </span>
      <br>
    </div>

    <div v-if="object.project && object.project.scope">
      <label class="mt-3">
        {{ $t('generic_controls.projects.table.scope') }}
      </label>
      <br>
      <span v-if="object.project" qid="io-name">
        <span class="badge badge-info mb-1" v-if="object.project.scope == 'system'">
          {{ $t('projects.system.label') }}
        </span>
        <span class="badge badge-info mb-1" v-if="object.project.scope == 'process'">
          {{ $t('projects.process.label') }}
        </span>
        <span class="badge badge-info mb-1" v-if="object.project.scope == 'activity'">
          {{ $t('projects.activity.label') }}
        </span>
        <span class="badge badge-info mb-1" v-if="object.project.scope == 'third_party'">
          {{ $t('projects.third_party.label') }}
        </span>
      </span>
      <br>
    </div>

    <div v-if="object.project && object.project.type">
      <label class="mt-3">
        {{ $t('generic_controls.projects.table.type') }}
      </label>
      <br>
      <span v-if="object.project" qid="io-name">
        <span class="badge badge-info mb-1">
          <span v-if="object.project.type == 'update'">
            {{ $t('projects.view.update_label') }}
          </span>
          <span v-if="object.project.type == 'validation'">
            {{ $t('projects.view.validation_label') }}
          </span>
          <span v-if="object.project.type == 'control'">
            {{ $t('projects.view.control_label') }}
          </span>
          <span v-if="object.project.type == 'security_measure'">
            {{ $t('projects.view.security_measure_label') }}
          </span>
          <span v-if="object.project.type == 'risk_assessment'">
            {{ $t('projects.view.assessment_label') }}
          </span>
        </span>
      </span>
      <br>
    </div>

    <div v-if="object.project && object.project.start_date">
      <label class="mt-3">
        {{ $t('generic_controls.projects.table.start_date') }}
      </label>
      <br>
      <span v-if="object.project" qid="io-name">
        {{ object.project.start_date | formatDate }}
      </span>
      <br>
    </div>


    <b-button class="mt-4 float-right"
              variant="success"
              @click="hideIOModal"
              qid="create-p-cancel-button"
    >
      {{ $t('processes.legal_basis.view.close') }}
    </b-button>
    <br>

  </b-modal>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'

  export default {
    name: 'ProjectTemplateViewModal',
    data: function() {
      return {
        error: '',
        processGroup: [],
        showInput: false,
        loadingItems: false,
        showArticleDescription: false
      }
    },
    props: {
      object: {
        type: [Object,Array]
      },
      showModal: {
        type: Boolean
      }
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getMedia() {
        return this.object.media
      }
    },
    watch: {
      // Watch when prop changes
      showModal: function (value) {
        if (value) {
          this.showIOModal()
        }
      }
    },
    methods: {
      showIOModal() {
        let self = this
        this.$refs['view-io'].show()
      },
      hideIOModal() {
        this.error = ''
        this.$emit('closeViewModal');
        this.showArticleDescription = false;
        this.$refs['view-io'].hide()
      }
    },
    components: {

    },
    created () {

    }
  }
</script>
