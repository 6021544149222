<template>
  <div>

    <div v-if="!show404 && !show403">
      <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

      <!-- Basic info tab -->
      <BasicInfoBlock :object="thirdParty" @updated="thirdPartyUpdated" :edit="edit"></BasicInfoBlock>

      <b-tabs class="mt-3" v-model="tabIndex">

        <!-- First tab -->
        <b-tab :title="$t('maintenance.third_parties.tab.basic_info')" active @click="resetHistoryTab">
          <br>
          <div class="card">
            <!-- <div class="card-body">
              <div class="content-view-body">
                <div>
                  <div class="mb-2">
                    <h5 class="h5">
                      <span class="float-right btn-sm btn-edit"
                            @click="showDescriptionInputFunction"
                            qid="edit-process-description"
                            v-if="!showDescriptionInput && isGranted([permissions.THIRD_PARTY_EDIT, permissions.THIRD_PARTY_EDIT_OWN], thirdParty) && (!getClient.parent || (getClient.parent && thirdParty.partition))"
                      >
                        <font-awesome-icon icon="pen"/>
                      </span>
                      <span class="float-right" v-if="showDescriptionInput">
                        <div class="text-right">
                          <b-button class="btn-sm inline-edit-button"
                                    variant="secondary"
                                    @click="cancelDescriptionForm"
                                    qid="process-description-cancel-button"
                          >
                            {{ $t('processes.legal_basis.create.cancel') }}
                          </b-button>
                          <b-button class="ml-3 btn-sm inline-edit-button"
                                    variant="success"
                                    @click="formDescriptionSubmit"
                                    qid="process-description-submit-button"
                                    v-if="isGranted([permissions.THIRD_PARTY_EDIT, permissions.THIRD_PARTY_EDIT_OWN], thirdParty) && (!getClient.parent || (getClient.parent && thirdParty.partition))"
                          >
                            {{ $t('processes.legal_basis.create.submit') }}
                          </b-button>
                        </div>
                      </span>
                      {{ $t('maintenance.third_parties.create.description_label') }}
                    </h5>
                    <span v-if="getGuide('update_description')">
                      <GuideButton :guide="getGuide('update_description')"></GuideButton>
                    </span>
                  </div>

                  <div v-if="showDescriptionInput">
                    <b-form-textarea
                      qid="edit-p-form-description"
                      v-model="processEditDescription"
                      rows="6"
                      :placeholder="$t('maintenance.third_parties.create.description_placeholder')"
                    ></b-form-textarea>
                  </div>
                  <div v-if="!showDescriptionInput" class="section-container">
                    <div v-if="thirdParty.description && thirdParty.description.length > descriptionLength">
                      <p v-if="showFullDescription" class="text-formatted">{{ thirdParty.description }}</p>
                      <p v-if="!showFullDescription" class="text-formatted">{{ thirdParty.description | truncate(descriptionLength) }}</p>
                      <div class="text-center mt-3" @click="showFullDescription=!showFullDescription">
                        <span class="cursor-pointer" v-if="!showFullDescription">
                          <span class="mr-2">
                            {{ $t('system.view_more') }}
                          </span>
                          <font-awesome-icon icon="chevron-down"/>
                        </span>
                        <span class="cursor-pointer" v-if="showFullDescription">
                          <span class="mr-2">
                            {{ $t('system.view_less') }}
                          </span>
                          <font-awesome-icon icon="chevron-up"/>
                        </span>
                      </div>
                    </div>
                    <p v-if="thirdParty.description && thirdParty.description.length <= descriptionLength"
                       class="text-formatted"
                    >{{thirdParty.description}}</p>
                    <p v-if="!thirdParty.description" class="text-muted">
                      {{ $t('system.missing_input') }}
                    </p>
                  </div>
                  <br>
                </div>
              </div>
            </div> -->

            <!-- Update description -->
            <DescriptionBlock :object="thirdParty" @updated="thirdPartyUpdated"></DescriptionBlock>

          </div>

          <br>

          <DocumentsTable :object="thirdParty" @updated="thirdPartyUpdated" v-if="getClient.contractAdvancedModule"></DocumentsTable>
          <br>
          <ContactsTable :object="thirdParty" @updated="thirdPartyUpdated" v-if="getClient.contractAdvancedModule"></ContactsTable>

        </b-tab>


        <!-- Measures tab -->
        <b-tab :title="$t('maintenance.third_parties.tab.measures')" class="mt-3" @click="resetHistoryTab" v-if="thirdParty.contract_measures && getClient.contractAdvancedModule">
          <MeasuresTable :object="thirdParty" @updated="thirdPartyUpdated"></MeasuresTable>
        </b-tab>


        <!-- DPA tab -->
        <b-tab :title="$t('maintenance.third_parties.tab.data_privacy')" class="sub-menu" @click="resetHistoryTab" v-if="thirdParty.data_privacy_feature && getClient.gdprModule">
          <b-tabs class="menu-smaller" v-model="smallTabIndex">

            <b-tab :title="$t('maintenance.third_parties.tab.dpa')" class="mt-3">
              <DataProcessorAgreement :object="thirdParty" @updated="thirdPartyUpdated"></DataProcessorAgreement>
            </b-tab>

            <b-tab :title="$t('maintenance.third_parties.tab.sub_data_processors')" class="mt-3" v-if="getClient.gdprAdvancedModule">
              <SubDataProcessors :object="thirdParty" @updated="thirdPartyUpdated" v-if="getClient.gdprAdvancedModule"></SubDataProcessors>
            </b-tab>

          </b-tabs>

          <!-- <TransfersDisclosures :object="thirdParty" @updated="thirdPartyUpdated"></TransfersDisclosures>
          <Inspection :object="thirdParty" @updated="thirdPartyUpdated"></Inspection> -->
        </b-tab>


        <!-- Risk scenarios tab -->
        <b-tab :title="$t('activities.tab.risk_management')" class="mt-3" @click="riskTabClicked" v-if="getClient.riskModule && thirdParty.risk_management_feature">
          <div v-if="showRiskTab">
            <RiskScenariosTable :object="thirdParty" @updated="thirdPartyUpdated"></RiskScenariosTable>
          </div>
        </b-tab>



        <!-- Third party assesments tab -->
        <b-tab :title="$t('maintenance.third_parties.tab.projects_assessments')" class="sub-menu" @click="assessmentsTabClicked" v-if="getClient.assessmentModule && thirdParty.project_and_assessment">
          <b-tabs class="menu-smaller">
            <b-tab :title="$t('systems.tab.assessments')" class="mt-3">
              <AssessmentsTable :thirdParty="thirdParty" @updated="loadContent"></AssessmentsTable>
            </b-tab>
            <b-tab :title="$t('maintenance.third_parties.tab.projects')" class="mt-3">
               <ProjectsTable :object="thirdParty" @updated="loadContent"></ProjectsTable>
            </b-tab>
          </b-tabs>
        </b-tab>


        <!-- Relations tab -->
        <b-tab :title="$t('maintenance.third_parties.tab.relations')" class="sub-menu" @click="resetHistoryTab" v-if="thirdParty.relations">
          <b-tabs class="menu-smaller">
            <!-- Activities tab -->
            <b-tab :title="$t('maintenance.third_parties.tab.activities')" class="mt-3" @click="resetHistoryTab" v-if="getClient.processingActivitiesModule">
              <ActivitiesTable :object="thirdParty"></ActivitiesTable>
            </b-tab>

            <!-- Systems tab -->
            <b-tab :title="$t('maintenance.third_parties.tab.systems')" class="mt-3" @click="resetHistoryTab" v-if="getClient.systemModule">
              <SystemsTable :object="thirdParty"></SystemsTable>
            </b-tab>

            <!-- Data tab -->
            <b-tab :title="$t('maintenance.third_parties.tab.data')" class="mt-3" @click="resetHistoryTab">
              <!-- Data objects table -->
              <div class="card pagination-margin"
                   qid="data-objects-section"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-6">
                      <h5 class="mb-2" qid="do-table-title">
                        {{ $t('systems.data_objects.title') }}
                      </h5>
                      <span v-if="getGuide('update_data')">
                        <GuideButton :guide="getGuide('update_data')"></GuideButton>
                      </span>
                    </div>
                    <div class="col-sm-6 text-right">
                      <button class="btn btn-success float-right ml-3 pl-3 pr-3"
                              @click="showCreateDOModal"
                              qid="system-do-create-button"
                              v-if="isGranted([permissions.THIRD_PARTY_EDIT, permissions.THIRD_PARTY_EDIT_OWN], thirdParty) && (!getClient.parent || (getClient.parent && thirdParty.partition))"
                      >
                        <font-awesome-icon icon="plus"/> {{ $t('systems.data_objects.create_button') }}
                      </button>
                      <b-form-input
                          type="text"
                          v-model="keywordValue"
                          class="float-right mw-300 search-bar"
                          v-on:input="debounceInput"
                          :placeholder="$t('maintenance.data_objects.search_placeholder')"
                          qid="do-search-input"
                      ></b-form-input>
                    </div>

                  </div>

                  <table class="table table-responsive-md table-list-secondary">
                    <thead>
                      <tr>
                        <th scope="col"
                            class="cursor-pointer"
                            @click="selectSort('name')"
                            qid="do-sort-name"
                        >
                          {{ $t('maintenance.data_objects.table.name') }}
                          <span class="ml-2">
                            <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'name'"/>
                            <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'name'"/>
                            <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'name'"/>
                          </span>
                        </th>
                        <th scope="col"
                            class="cursor-pointer"
                            @click="selectSort('category')"
                            qid="do-sort-category"
                        >
                          {{ $t('maintenance.data_objects.table.category') }}
                          <span class="ml-2">
                            <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'category'"/>
                            <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'category'"/>
                            <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'category'"/>
                          </span>
                        </th>
                        <th scope="col"
                            class="cursor-pointer"
                            @click="selectSort('classification')"
                            qid="do-sort-classification"
                        >
                          {{ $t('maintenance.data_objects.table.classification') }}
                          <span class="ml-2">
                            <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'classification'"/>
                            <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'classification'"/>
                            <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'classification'"/>
                          </span>
                        </th>
                        <th scope="col"
                            class="cursor-pointer"
                            @click="selectSort('business_classification')"
                            qid="do-sort-classification"
                        >
                          {{ $t('maintenance.data_objects.table.business_classification') }}
                          <span class="ml-2">
                            <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'business_classification'"/>
                            <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'business_classification'"/>
                            <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'business_classification'"/>
                          </span>
                        </th>
                        <th scope="col">
                          {{ $t('systems.data_objects.table.mapped_to_activity') }}
                        </th>
                        <th scope="col">
                          {{ $t('systems.data_objects.table.tags') }}
                        </th>
                        <th scope="col">
                          {{ $t('table.last_updated') }}
                        </th>
                        <th width="130"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="object in DOTableItems">
                        <td>
                          <span @click="openViewDataModal(object)" class="btn btn-link cursor-pointer text-left">
                            {{ object.data_object.name }}
                          </span>
                        </td>
                        <td>
                          <span v-if="object.data_object && object.data_object.category">
                            <span class="badge" :title="object.data_object.category">
                              <font-awesome-icon icon="shapes" size="xs"/>
                              {{ object.data_object.category | truncate(35)}}
                            </span>
                          </span>
                        </td>
                        <td>
                          <span v-if="object.data_object.classification" class="text-normal">
                            <span class="badge">
                              {{ $t('classification.' + $options.filters.slugify(object.data_object.classification)) }}
                            </span>
                          </span>
                        </td>
                        <td>
                          <span v-if="object.data_object.business_classification" class="text-normal">
                            <span class="badge">
                              {{ $t('classification.' + $options.filters.slugify(object.data_object.business_classification)) }}
                            </span>
                          </span>
                        </td>
                        <td>
                          <span class="text-normal">
                            <span v-if="object.activity_count">
                              <span class="badge-pill badge-success mr-2" >
                                <font-awesome-icon icon="check" size="xs"/>
                              </span>
                              <button
                                  @click="openViewDataObjectActivitiesModal(object)"
                                  class="btn btn-link text-success btn-sm text-left"
                              >
                                <span v-if="object.activity_count > 1">
                                  {{ object.activity_count }} {{ $t('activities.title') | lowerCase }}
                                </span>
                                <span v-if="object.activity_count == 1">
                                  {{ object.activity_count }} {{ $t('activities.log.title') | lowerCase}}
                                </span>
                              </button>
                            </span>
                            <span v-if="!object.activity_count">
                              <font-awesome-icon icon="times-circle" class="icon-danger"/>
                              {{ $t('system.no') }}
                            </span>
                          </span>
                        </td>
                        <td>
                          <span class="mr-1" v-for="tag in object.data_object.tags_translated" qid="p-tags-list">
                            <span class="badge mb-1" :title="tag.name_translated">
                              <font-awesome-icon icon="tag" size="xs"/>
                              {{tag.name_translated | truncate(35)}}
                            </span>
                          </span>
                        </td>
                        <td class="text-normal">
                          {{ object.data_object.updated_at | formatDate }}
                        </td>
                        <td align="right">
                          <span @click="openViewDataModal(object)" class="btn btn-view">
                            <font-awesome-icon icon="eye"/>
                          </span>
                          <button class="btn btn-delete"
                                  qid="delete-do-modal"
                                  @click="showDOConfirmDeleteModal(object)"
                                  v-if="!object.activity_count && isGranted([permissions.THIRD_PARTY_EDIT, permissions.THIRD_PARTY_EDIT_OWN], thirdParty) && (!getClient.parent || (getClient.parent && thirdParty.partition))"
                          >
                            <font-awesome-icon icon="trash-alt" />
                          </button>
                        </td>
                      </tr>
                      <tr v-if="!DOLoading && !DOTableItems.length" qid="do-no-results-row" class="text-center">
                        <td colspan="10">
                          <h5 class="mt-3">
                            {{ $t('system.no_results') }}
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="card-body">
                  <div class="pagination row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="float-left">
                          <span class="text-muted pagination-counter">
                            {{ $t('pagination.entries_per_page') }}:
                          </span>
                        </div>
                        <div class="float-left">
                          <multiselect
                              v-model="perPage"
                              :options="perPageOptions"
                              track-by="value"
                              label="value"
                              :select-label="$t('system.dropdown_select_label')"
                              :selected-label="$t('system.dropdown_selected_label')"
                              :deselect-label="$t('system.dropdown_deselect_label')"
                              qid="create-org-form-organization-level"
                              @select="perPageChanged"
                              :placeholder="selectedPerPage"
                              @remove="perPageRemoved"
                          ></multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <b-pagination
                          class="float-right"
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="selectedPerPage"
                          aria-controls="my-table"
                          @change="pageChanged"
                          limit="1"
                      >
                        <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
                        <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
                        <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
                        <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
                        <div slot="ellipsis-text">
                          <b-spinner small type="grow"></b-spinner>
                          <b-spinner small type="grow"></b-spinner>
                          <b-spinner small type="grow"></b-spinner>
                        </div>
                        <span class="text-muted" slot="page" slot-scope="{ page, active }">
                          <span v-if="active">{{getPerPage}}</span>
                        </span>
                      </b-pagination>
                    </div>
                  </div>
                </div>
              </div>

            </b-tab>
          </b-tabs>
        </b-tab>


        <!-- Validations tab -->
        <b-tab :title="$t('maintenance.third_parties.tab.validations')"
               class="mt-3 validations-tab"
               @click="resetHistoryTab"
               v-if="thirdParty.validation_activation && getClient.contractAdvancedModule"
        >
          <!-- Validation section component -->
          <Validations
              :object="thirdParty"
              type="third_party"
              :checklist="thirdParty.validationOptions"
              @updated="updateValidation"
              :permission="['THIRD_PARTY_VALIDATE', 'THIRD_PARTY_VALIDATE']"
              createButton="secondary"
              activeGuide="validate_third_party"
          ></Validations>
        </b-tab>

        <!-- Risk scenario history tab -->
        <b-tab :title="$t('maintenance.third_parties.tab.history')" class="mt-3" @click="historyTabClicked">
          <div v-if="showHistoryTab">
            <ThirdPartyHistory :object="thirdParty"></ThirdPartyHistory>
          </div>
        </b-tab>

      </b-tabs>


      <!-- Add new data object -->
      <b-modal ref="add-data-object" hide-footer @hide="hideCreateDOModal">
        <div class="d-block text-center">
          <h4 qid="add-do-title">
            {{ $t('systems.data_objects.create.title') }}
          </h4>
        </div>
        <b-alert v-if="error.length"
                 show
                 variant="danger"
                 qid="create-do-error"
        >
          {{ error }}
        </b-alert>
        <b-form @submit="DOFormSubmit" qid="create-do-form">
          <div class="form-container">
            <b-form-group>
              <label qid="create-do-pg-label">
                {{ $t('systems.data_objects.create.do_label') }}
              </label>
              <multiselect
                  v-model="selectedDO"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  :options="dataObjects"
                  :placeholder="$t('systems.data_objects.create.do_placeholder')"
                  label="name"
                  @search-change="loadDataObjects"
                  track-by="slug"
                  qid="create-do-form-do"
              ></multiselect>
            </b-form-group>
            <div v-if="selectedDO && selectedDO.description"
                 class="text-center mb-3 mt-4"
            >
              <span class="cursor-pointer">
                <span class="mr-2"
                      @click="showDataObjectDescription = !showDataObjectDescription"
                      qid="data-objects-description-expand"
                >
                  {{$t('systems.data_objects.create.do_description_expand')}}
                </span>
                <font-awesome-icon icon="chevron-down" v-if="!showDataObjectDescription"/>
                <font-awesome-icon icon="chevron-up" v-if="showDataObjectDescription"/>
              </span>
            </div>
            <div v-if="selectedDO && selectedDO.description && showDataObjectDescription" class="mb-4 mt-2">
              <span class="text-formatted">
                {{ selectedDO.description }}
              </span>
            </div>

            <div v-if="selectedDO && selectedDO.classification">
              <label class="mt-3">
                  {{ $t('maintenance.data_objects.create.classification_label') }}
              </label>
              <br>
              <span v-if="selectedDO">
                {{ selectedDO.classification }}
              </span>
            </div>
            <div v-if="selectedDO && selectedDO.business_classification">
              <label class="mt-3">
                  {{ $t('maintenance.data_objects.create.business_classification_label') }}
              </label>
              <br>
              <span v-if="selectedDO">
                {{ selectedDO.business_classification }}
              </span>
            </div>
            <div v-if="selectedDO && selectedDO.category">
              <label class="mt-3">
                {{ $t('maintenance.data_objects.create.category_label') }}
              </label>
              <br>
              <span v-if="selectedDO">
                {{ selectedDO.category }}
              </span>
            </div>
            <div v-if="selectedDO && selectedDO.tags_translated && selectedDO.tags_translated.length" class="mb-3">
              <label class="mt-3">
                {{ $t('maintenance.data_objects.create.tags_label') }}
              </label>
              <br>
              <span class="mr-1" v-for="tag in selectedDO.tags_translated" qid="do-tags-list">
                <span class="badge badge-info mb-1" :title="tag.name_translated">
                  {{tag.name_translated | truncate(35)}}
                </span>
              </span>
            </div>
          </div>
          <div class="text-right">
            <b-button class="mt-3"
                      variant="secondary"
                      @click="hideCreateDOModal"
                      qid="create-lb-cancel-button"
            >
              {{ $t('systems.policies.create.cancel') }}
            </b-button>
            <b-button type="submit"
                      class="mt-3 ml-3"
                      variant="success"
                      :disabled="buttonDisabled"
                      qid="create-lb-submit-button"
            >
              <span v-if="buttonDisabled" class="mr-1">
                <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
              </span>
              {{ $t('systems.policies.create.submit') }}
            </b-button>
          </div>
        </b-form>
      </b-modal>

      <!-- Delete data object modal template -->
      <b-modal ref="delete-data-object" hide-footer>
        <div class="d-block text-center">
          <h4 qid="delete-do-title">
            {{ $t('systems.data_objects.delete.title') }}
          </h4>
        </div>
        <h5 class="text-center">
          {{ $t('systems.data_objects.delete.description') }}
          <span v-if="itemToDelete.data_object">{{ itemToDelete.data_object.name }}</span>
        </h5>
        <h5 class="text-center">
          {{ $t('systems.data_objects.delete.are_you_sure') }}
        </h5>
        <div class="text-center">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideDOConfirmDeleteModal"
                    qid="delete-data-object-cancel-button"
          >
            {{ $t('systems.data_objects.delete.cancel') }}
          </b-button>
          <b-button @click="confirmDeleteDO(itemToDelete)"
                    class="mt-3 ml-3"
                    variant="success"
                    qid="delete-data-objects-submit-button"
          >
            {{ $t('systems.data_objects.delete.submit') }}
          </b-button>
        </div>
      </b-modal>

      <ConfirmRemoveAttachment
          :object="selectedFile"
          :show="showDeleteAttachmentModal"
          type="file"
          @closeConfirmModal="closeDeleteAttachmentModal"
      ></ConfirmRemoveAttachment>

      <ConfirmRemoveAttachment
          :object="selectedLink"
          :show="showDeleteLinkModal"
          type="link"
          @closeConfirmModal="closeDeleteLinkModal"
      ></ConfirmRemoveAttachment>

      <DataObjectActivitiesModal
          :object="selectedDataObject"
          :showModal="viewDataObjectActivitiesModal"
          :thirdParty="thirdParty"
          @closeViewModal="handleCloseViewDataObjectActivitiesModal"
          v-if="selectedDataObject"
      ></DataObjectActivitiesModal>

      <DataViewModal
          :object="selectedDataObject"
          :showModal="viewDataModal"
          @closeViewModal="handleCloseViewDataModal"
      ></DataViewModal>
    </div>


    <div v-if="show404">
      <page404></page404>
    </div>

    <div v-if="show403">
      <page403></page403>
    </div>

  </div>
</template>

<script>
import piincHttp from '@/util/configureAxios.js'
import {config} from '@/config.js'
import Datepicker from 'vuejs-datepicker';
import Validations from '@/views/Validations/Validations.vue';
import ActivitiesTable from '@/views/ThirdParty/ActivitiesTable.vue';
import SystemsTable from '@/views/ThirdParty/SystemsTable.vue';
import DataProcessorAgreement from '@/views/ThirdParty/DataProcessorAgreement.vue';
import SubDataProcessors from '@/views/ThirdParty/SubDataProcessors.vue';
import BasicInfoBlock from '@/views/ThirdParty/BasicInfoBlock.vue';
import TransfersDisclosures from '@/views/ThirdParty/TransfersDisclosures.vue';
import Inspection from '@/views/ThirdParty/Inspection.vue';
import DataViewModal from '@/views/ThirdParty/DataViewModal.vue';
import Avatar from '@/components/Avatar.vue';
import ConfirmRemoveAttachment from '@/components/ConfirmRemoveAttachment.vue';
import DataObjectActivitiesModal from '@/views/ThirdParty/DataObjectActivitiesModal.vue';
import ThirdPartyHistory from '@/views/ThirdParty/ThirdPartyHistory.vue';
import RiskScenariosTable from '@/views/ThirdParty/RiskScenariosTable.vue';
import ShowMoreModal from '@/components/ShowMoreModal.vue';
import AssessmentsTable from '@/views/ThirdParty/AssessmentsTable.vue';
import ProjectsTable from '@/views/ThirdParty/ProjectsTable.vue';
import MeasuresTable from '@/views/ThirdParty/MeasuresTable.vue';
import DocumentsTable from '@/views/ThirdParty/DocumentsTable.vue';
import ContactsTable from '@/views/ThirdParty/ContactsTable.vue';
import GuideButton from '@/components/guide/GuideButton.vue';
import DescriptionBlock from '@/views/ThirdParty/DescriptionBlock.vue';
import page404 from '@/components/404block.vue';
import page403 from '@/components/403block.vue';


export default {
  name: 'ThirdPartyView',
  data: function() {
    return {
      breadcrumbItems: [
        {
          text: this.$t('dashboard.title'),
          to: '/'
        },
        {
          text: this.$t('maintenance.third_parties.title'),
          to: '/contracts'
        }
      ],
      thirdParty: {},
      showInput: false,
      showSingleLinkLabel: true,
      descriptionLength: config.descriptionLength,
      edit: this.$route.params.edit,
      inputLink: '',
      showFullDescription: false,
      showFullContact: false,
      itemToDelete: {},
      tpForm: {
        name: '',
        description: '',
        type: [],
        inspection: [],
        inspection_updated_at: '',
        data_processor_agreement: [],
        dpa_updated_at: '',
        countries: [],
        client:'',
        data_objects: [],
        tags: [],
        users: [],
        transfers_disclosures_third_countries: [],
        third_country_contract_type: [],
        third_country_contract_date: '',
        dpa_contract_type: []
      },
      error: '',
      countries: [],
      selectedCountries: [],
      inspectionItems: [
        { name: this.$t('maintenance.third_parties.inspection.no'), value: 'No' },
        { name: this.$t('maintenance.third_parties.inspection.external_audit'), value: 'External audit' },
        { name: this.$t('maintenance.third_parties.inspection.site_visit'), value: 'Site visit' },
        { name: this.$t('maintenance.third_parties.inspection.third-party_self-audit'), value: 'Third-party self-audit' }
      ],
      dpaItems: [
        { name: this.$t('maintenance.third_parties.data_processor_agreement.no'), value: 'No' },
        { name: this.$t('maintenance.third_parties.data_processor_agreement.yes'), value: 'Yes' },
        { name: this.$t('maintenance.third_parties.data_processor_agreement.n/a'), value: 'N/A' }
      ],
      dpaContractTypes: [
        { name: this.$t('maintenance.third_parties.dpa_contract_type.contract_model_clause'), value: 'Contract model clause' },
        { name: this.$t('maintenance.third_parties.dpa_contract_type.eu/us_privacy_shield'), value: 'EU/US Privacy Shield' },
        { name: this.$t('maintenance.third_parties.dpa_contract_type.adequacy_decision'), value: 'Adequacy decision' },
        { name: this.$t('maintenance.third_parties.dpa_contract_type.binding_corporate_rules'), value: 'Binding corporate rules' }
      ],
      transfersDisclosuresThirdCountries : [
        { name: this.$t('maintenance.third_parties.transfers_disclosures_third_countries.no'), value: 'No' },
        { name: this.$t('maintenance.third_parties.transfers_disclosures_third_countries.yes'), value: 'Yes' },
        { name: this.$t('maintenance.third_parties.transfers_disclosures_third_countries.n/a'), value: 'N/A' }
      ],
      thirdCountryContractTypes: [
        { name: this.$t('maintenance.third_parties.third_country_contract_type.contract_model_clause'), value: 'Contract model clause' },
        { name: this.$t('maintenance.third_parties.third_country_contract_type.eu/us_privacy_shield'), value: 'EU/US Privacy Shield' },
        { name: this.$t('maintenance.third_parties.third_country_contract_type.adequacy_decision'), value: 'Adequacy decision' },
        { name: this.$t('maintenance.third_parties.third_country_contract_type.binding_corporate_rules'), value: 'Binding corporate rules' }
      ],
      types: [],
      typeInput: '',
      dataObjects: [],
      selectedDataObjects: [],
      buttonDisabled: false,
      showDescriptionInput: false,
      processEditDescription: '',
      showContactInput: false,
      processEditContact: '',
      selectedFile: {},
      selectedLink: {},
      showDeleteAttachmentModal: false,
      showDeleteLinkModal: false,
      tabIndex: 0,
      smallTabIndex: 0,
      myGuides: [],
      selectedTags: [],
      tags: [],
      users: [],
      newTags: [],
      DOTableItems: [],
      DOLoading: false,
      selectedDO: [],
      selectedUsers: [],
      selectedDataObject: {},
      viewDataObjectActivitiesModal: false,
      selectedDataObjectActivities: [],
      showHistoryTab: false,
      showRiskTab: false,
      showDataObjectDescription: false,
      // Pagination
      perPageOptions: [
        { name: '10', value: '10' },
        { name: '20', value: '20' },
        { name: '50', value: '50' },
        { name: '100', value: '100' }
      ],
      perPage: '',
      selectedPerPage: "10",
      currentPage: 1,
      totalRows: 0,
      keywordValue: '',
      selectedSort: '',
      selectedDirection: '',
      myTasks: [],
      selectedItem: {},
      showTagsModal: false,
      viewDataModal: false,
      showAssessmentsTab: false,
      show404: false,
      show403: false,
    }
  },
  components: {
    Datepicker,
    Validations,
    Avatar,
    ActivitiesTable,
    SystemsTable,
    ConfirmRemoveAttachment,
    DataProcessorAgreement,
    TransfersDisclosures,
    Inspection,
    DataObjectActivitiesModal,
    ThirdPartyHistory,
    RiskScenariosTable,
    ShowMoreModal,
    DataViewModal,
    AssessmentsTable,
    ProjectsTable,
    BasicInfoBlock,
    MeasuresTable,
    DocumentsTable,
    ContactsTable,
    GuideButton,
    SubDataProcessors,
    DescriptionBlock,
    page403,
    page404
  },
  computed: {
    rows() {
      // Use computed value for rows because of dynamic update
      return this.totalRows
    },
    getClient() {
      return this.$store.state.client
    },
    getTabIndex() {
      return this.$store.state.tabIndex
    },
    getStorePerPage() {
      return this.$store.state.perPage
    },
    getPerPage() {
      let page = this.currentPage;
      let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
      let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
      let selectedOf = this.totalRows;

      if(selectedTo > selectedOf){
        selectedTo = selectedOf
      }

      return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
    },
    getReloadContent() {
      return this.$store.state.updateContent
    },
    getReloadPage() {
      return this.$store.state.updatePage
    }
  },
  watch: {
    getClient: function (newValue, oldValue) {
      if (newValue && oldValue && newValue.slug && oldValue.slug && newValue.slug !== oldValue.slug) {
        let self = this;
        setTimeout(function(){
          if (self.$route.name == 'contracts.view') {
            self.goToList()
          }
        }, 800);
      } else {
        this.loadContent()
        this.loadTypes()
        this.loadUsers()
        this.loadCountries()
        this.loadDataObjects()
      }
    },
    getReloadContent: function () {
      if (!this.showOnTask) {
        if (this.$store.state.updatedObject && this.$store.state.updatedObject.id) {
          if (this.$store.state.updatedObject.id == this.thirdParty.id && this.$store.state.updatedObject.who_am_i == this.thirdParty.who_am_i) {
            this.thirdParty = this.$store.state.updatedObject
          }
        }
      }
    },
    getReloadPage: function () {
      if (this.$store.state.updatedObject && this.$store.state.updatedObject.id && this.$store.state.updatedObject.id == this.thirdParty.id && this.$store.state.updatedObject.who_am_i == this.thirdParty.who_am_i) {
        this.$router.push({ name: 'contracts.view', params: { slug: this.$store.state.updatedObject.slug }, query: { task: this.$route.query.task }})
      } else {
        this.$router.push({ name: 'contracts.view', params: { slug: this.thirdParty.slug }, query: { task: this.$route.query.task }})
      }
    },
    getTabIndex: function (newValue, oldValue) {
      this.handleUpdateTab(newValue)
    }
  },
  methods: {
    debounceInput: _.debounce(function() {
      this.loadDO()
    }, 400),
    openTagsModal(object) {
      this.selectedItem = object
      this.showTagsModal = true
    },
    closeTagsModal() {
      this.selectedItem = {}
      this.showTagsModal = false
    },
    handleCloseViewDataModal () {
      this.viewDataModal = false;
    },
    openViewDataModal(object) {
      this.selectedDataObject = object
      this.viewDataModal = true
    },
    getReducedTags(tags) {
      let reducedTags = []
      if (tags.length > 3) {
        reducedTags = _.cloneDeep(tags);
        reducedTags = reducedTags.slice(0, 3);
      } else {
        reducedTags = tags;
      }
      return reducedTags;
    },
    selectSort(sort) {
      // If old and new sort are same, switch just direction
      if (sort === this.selectedSort) {
        this.selectedDirection = this.selectedDirection === 'desc' ? 'asc' : 'desc';
      } else {
        // If different sort, set sort and set direction to default
        this.selectedDirection = 'desc'
        this.selectedSort = sort;
      }
      this.loadDO();
    },
    pageChanged(page) {
      this.loadDO(page)
    },
    perPageChanged(perPage) {
      let self = this;
      // Load content when page changed listener is triggered
      this.loadDO(1, perPage.value)
      self.selectedPerPage = perPage.value
      this.$store.commit('storePerPage', this.selectedPerPage);
    },
    perPageRemoved() {
      let self = this;
      // Load content when page changed listener is triggered
      self.selectedPerPage = self.perPageOptions[0].value
      this.loadDO(1, self.selectedPerPage)
    },
    goToList() {
      this.$router.push('/contracts')
    },
    historyTabClicked() {
      this.showAssessmentsTab = false;
      this.showHistoryTab = true;
      this.showRiskTab = false;
    },
    riskTabClicked() {
      this.showAssessmentsTab = false;
      this.showRiskTab = true;
      this.showHistoryTab = false;
    },
    assessmentsTabClicked() {
      this.showAssessmentsTab = true;
      this.showHistoryTab = false;
      this.showRiskTab = false;
    },
    resetHistoryTab() {
      this.showHistoryTab = false;
      this.showRiskTab = false;
    },
    openViewDataObjectActivitiesModal(object){
      this.selectedDataObject = object
      this.viewDataObjectActivitiesModal = true
    },
    handleCloseViewDataObjectActivitiesModal () {
      this.selectedDataObject = []
      this.viewDataObjectActivitiesModal = false;
    },
    getTask(code) {
      let existingTask = _.find(this.myTasks, function(object) {
        return object.code == code
      });
      return existingTask
    },
    getGuide(code) {
      let existingGuide = _.find(this.myGuides, function(object) {
        return object.scope == code && object.type == 'Third party' && object.enabled
      });
      return existingGuide
    },
    loadGuides(code) {
      let self = this;
      let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

      piincHttp.get('guides', { params:
        {
          per_page: 1,
          language: 'en',
          type: 'Third party',
          scope: code,
          client: this.getClient.slug
        }
      }).then(function(response) {
        if (response.data.items.length) {
          self.myGuides.push(response.data.items[0])
        }
      }, function() {});
    },
    removeUser(index) {
      let selectedItem = this.selectedUsers[index]
      selectedItem.owner = false;
      this.$set(this.selectedUsers, index, selectedItem)
      // Remove item from selected users array
      this.selectedUsers.splice(index, 1);
    },
    removeUserFromDropdown(item) {
      item.owner = false;
    },
    showDescriptionInputFunction() {
      this.showDescriptionInput = true;
      this.processEditDescription = this.thirdParty.description;
    },
    formDescriptionSubmit() {
      let self = this;
      piincHttp.put('third-parties/' + this.$route.params.slug, {
        description: this.processEditDescription
      }).then(function(response) {
        self.breadcrumbItems.pop();
        self.loadContent();
        self.showDescriptionInput = false;
        self.$toastr('success', self.$t('maintenance.third_parties.update.success'))
      }, function(error) {
        if (error.status !== 200) {
          self.error = error.data.message;
        }
      })
    },
    cancelDescriptionForm() {
      this.showDescriptionInput = false;
      this.processEditDescription = this.thirdParty.description;
    },
    setOwner(index) {
      let selectedItem = this.selectedUsers[index]
      _.forEach(this.selectedUsers, function (item) {
        item.owner = false;
      })
      selectedItem.owner = true;
      this.$set(this.selectedUsers, index, selectedItem)
    },
    resetFile() {
      let imagefile = document.querySelector('#file');
      imagefile.value = '';
    },
    handleUpdateTab(response) {
      let self = this
      setTimeout(function(){
        if (response == 'validations') {
          let index = 6;

          if (!self.getClient.gdprModule) {
            index = index - 1;
          }

          self.tabIndex = index
        }
        if (response == 'risk-management') {
          let index = 3;

          if (!self.getClient.gdprModule) {
            index = index - 1;
          }

          self.tabIndex = index
          self.riskTabClicked()
        }
        if (response == 'contract-measures') {
          let index = 1;

          self.tabIndex = index
        }
        if (response == 'data-processor-agreement') {
          let index = 2;

          if (!self.getClient.contractAdvancedModule) {
            index = index - 1;
          }

          self.tabIndex = index
          self.smallTabIndex = 0
        }
        if (response == 'subdata-processors') {
          let index = 2;

          if (!self.getClient.contractAdvancedModule) {
            index = index - 1;
          }

          self.tabIndex = index
          self.smallTabIndex = 1
        }
      }, 2000);
    },
    thirdPartyUpdated() {
      this.breadcrumbItems.pop();
      this.loadContent()
    },
    updateValidation(validation) {
      // Event emmited from validation component, update active validation
      this.thirdParty.validation = validation
    },
    openDeleteAttachmentModal(object) {
      this.selectedFile = object
      this.showDeleteAttachmentModal = true
    },
    closeDeleteAttachmentModal () {
      this.selectedFile = {}
      this.showDeleteAttachmentModal = false;
      // Remove last item in breadcrumbs, because it will be added again on load content
      this.breadcrumbItems.pop();
      this.loadContent();
    },
    openDeleteLinkModal(object) {
      this.selectedLink = object
      this.showDeleteLinkModal = true
    },
    closeDeleteLinkModal () {
      this.selectedLink = {}
      this.showDeleteLinkModal = false;
      // Remove last item in breadcrumbs, because it will be added again on load content
      this.breadcrumbItems.pop();
      this.loadContent();
    },
    handleLink() {
      let self = this;
      let formData = new FormData();

      if (!this.inputLink.startsWith('http')) {
        this.inputLink = 'http://' + this.inputLink
      }

      formData.append("url", this.inputLink);
      formData.append("model_type", this.thirdParty.who_am_i);
      formData.append("model_id", this.thirdParty.id);

      piincHttp.post('links', formData).then(function() {
        // Remove last item in breadcrumbs, because it will be added again on load content
        self.breadcrumbItems.pop();
        self.$toastr('success', self.$t('maintenance.third_parties.link_add_success'))
        self.loadContent()
        self.inputLink = ''
        self.showInput = false
      }, function() {})
    },
    downloadMedia(object){
      piincHttp.get('documents/' + object.id + '/download', {responseType: 'blob'}).then(function(response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = object.file_name;

        link.click();
        link.remove()
        window.URL.revokeObjectURL(url);
      }, function() {});
    },
    onFileChange(file) {
      let files = file.target.files;
      let self = this;
      let formData = new FormData();
      let sizeError = false;

      for (var i = 0; i < files.length; i++) {
        // Equal to 10 MB
        if (files[i].size > 10485760) {
          sizeError = true;
        }
        formData.append('document[]', files[i]);
      }

      formData.append("name", "documents");
      formData.append("model_type", this.thirdParty.who_am_i);
      formData.append("model_id", this.thirdParty.id);

      if (sizeError) {
        this.error = this.$t('system.file_size')
      } else {
        piincHttp.post('documents', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function() {
          // Remove last item in breadcrumbs, because it will be added again on load content
          self.breadcrumbItems.pop();
          self.$toastr('success', self.$t('maintenance.third_parties.media_add_success'))
          self.error = ''
          self.loadContent()
        }, function(error) {
          self.error = error.data.message;
          if (error.data.errors['document.0']) {
            self.error += ' ' + error.data.errors['document.0'][0]
          }
        })
      }
    },
    getUserAvatar(user){
      if (user && user.avatar) {
        return user.avatar;
      } else {
        return require('@/assets/avatar.png');
      }
    },
    showConfirmDeleteModal(object) {
      this.itemToDelete = object
      this.$refs['delete-third-party'].show()
    },
    hideConfirmDeleteModal() {
      this.itemToDelete = {}
      this.$refs['delete-third-party'].hide()
    },
    confirmDeleteTP(object) {
      let self = this;
      piincHttp.delete('third-parties/' + object.slug).then(function() {
        self.hideConfirmDeleteModal();
        self.goToList();
        self.$store.commit('storeUpdateTasks', true);
        self.$store.commit('storeActiveTask', {});
        // Trigger toastr message after modal close
        self.$toastr('success', self.$t('maintenance.third_parties.delete.success'))
      }, function() {});
    },
    showEditTPModal(object) {
      this.loadTypes();
      this.loadTags();
      let self = this;

      if (object.users && object.users.length) {
        self.selectedUsers = [];
        _.forEach(object.users, function (user) {
          let matchedUser = _.cloneDeep(user)
          if (user.id == object.owner) {
            matchedUser.owner = true
          }
          self.selectedUsers.push(matchedUser)
        })
      }

      this.tpForm.transfers_disclosures_third_countries = []
      if (object.transfers_disclosures_third_countries) {
        this.tpForm.transfers_disclosures_third_countries = _.find(this.transfersDisclosuresThirdCountries, function(item) {
          return item.value === object.transfers_disclosures_third_countries;
        });
      }
      this.tpForm.third_country_contract_type = []
      if (object.third_country_contract_type) {
        this.tpForm.third_country_contract_type = _.find(this.thirdCountryContractTypes, function(item) {
          return item.value === object.third_country_contract_type;
        });
      }
      this.tpForm.dpa_contract_type = []
      if (object.dpa_contract_type) {
        this.tpForm.dpa_contract_type = _.find(this.dpaContractTypes, function(item) {
          return item.value === object.dpa_contract_type;
        });
      }

      if (object.inspection) {
        this.tpForm.inspection = _.find(this.inspectionItems, function(item) {
          return item.value === object.inspection;
        });
      }
      if (object.data_processor_agreement) {
        this.tpForm.data_processor_agreement = _.find(this.dpaItems, function(item) {
          return item.value === object.data_processor_agreement;
        });
      }
      // set selected countries on edit form
      if (object.countries && object.countries.length) {
        self.selectedCountries = [];
        _.forEach(object.countries, function (value, key) {
          _.find(self.countries, function(item) {
            if (item.name === value) {
              self.selectedCountries.push(item)
            }
          });
        })
      }

      if (object.tags_translated && object.tags_translated.length) {
        this.selectedTags = _.cloneDeep(object.tags_translated);
      }

      if (object.data_objects && object.data_objects.length) {
        this.selectedDataObjects = _.cloneDeep(object.data_objects);
      }

      if (object.type) {
        this.tpForm.type = []
        _.find(this.types, function(item) {
          if (item.type == object.type) {
            self.tpForm.type = item;
          }
        });
      }

      this.tpForm.slug = object.slug;
      this.tpForm.name = object.name;
      this.tpForm.description = object.description;
      this.tpForm.inspection_updated_at = object.inspection_updated_at;
      this.tpForm.dpa_updated_at = object.dpa_updated_at;
      this.tpForm.third_country_contract_date = object.third_country_contract_date;
      this.$refs['edit-third-party'].show()
    },
    hideEditTPModal() {
      this.typeInput = '';
      this.selectedUsers = []
      this.$refs['edit-third-party'].hide()
    },
    editTPFormSubmit(evt) {
      this.buttonDisabled = true;
      var moment = require('moment');
      // Keep the reference in a variable
      // to use it inside function called when promise returned
      let self = this;
      let selectedOwner = false;
      evt.preventDefault();
      this.tpForm.client = this.getClient.slug;
      this.tpForm.countries = [];

      for (var i = 0; i < this.selectedCountries.length; i++) {
        this.tpForm.countries.push(this.selectedCountries[i].name);
      }

      this.tpForm.users = [];
      if (this.selectedUsers && this.selectedUsers.length) {
        for (var i = 0; i < this.selectedUsers.length; i++) {
          this.tpForm.users.push(this.selectedUsers[i].id);
          if (this.selectedUsers[i].owner) {
            selectedOwner = true;
            this.tpForm.owner = this.selectedUsers[i].id;
          }
        }
      } else {
        selectedOwner = true;
      }

      this.tpForm.data_processor_agreement = this.tpForm.data_processor_agreement ? this.tpForm.data_processor_agreement.value : '';
      this.tpForm.inspection = this.tpForm.inspection ? this.tpForm.inspection.value : '';
      this.tpForm.transfers_disclosures_third_countries = this.tpForm.transfers_disclosures_third_countries ? this.tpForm.transfers_disclosures_third_countries.value : '';
      this.tpForm.third_country_contract_type = this.tpForm.third_country_contract_type ? this.tpForm.third_country_contract_type.value : '';
      this.tpForm.dpa_contract_type = this.tpForm.dpa_contract_type ? this.tpForm.dpa_contract_type.value : '';
      // transform selected date into correct format for API
      this.tpForm.inspection_updated_at = this.tpForm.inspection_updated_at ? moment(this.tpForm.inspection_updated_at).format('YYYY-MM-DD') : ''
      this.tpForm.dpa_updated_at = this.tpForm.dpa_updated_at ? moment(this.tpForm.dpa_updated_at).format('YYYY-MM-DD') : ''
      this.tpForm.third_country_contract_date = this.tpForm.third_country_contract_date ? moment(this.tpForm.third_country_contract_date).format('YYYY-MM-DD') : ''

      // this.tpForm.data_objects = []
      // if (this.selectedDataObjects && this.selectedDataObjects.length) {
      //   for (var i = 0; i < this.selectedDataObjects.length; i++) {
      //     this.tpForm.data_objects.push(this.selectedDataObjects[i].id);
      //   }
      // }

      this.tpForm.tags = [];
      if (this.selectedTags && this.selectedTags.length) {
        for (var i = 0; i < this.selectedTags.length; i++) {
          this.tpForm.tags.push(this.selectedTags[i].name_translated);
        }
      }

      if (this.typeInput && this.typeInput.length) {
        this.tpForm.type = this.typeInput
      } else {
        if (this.tpForm.type && this.tpForm.type.type) {
          this.tpForm.type = this.tpForm.type.type
        } else {
          this.tpForm.type = '';
        }
      }

      if (selectedOwner) {
        piincHttp.put('third-parties/' + this.tpForm.slug, this.tpForm).then(function(response) {
          self.hideEditTPModal();
          self.buttonDisabled = false;
          // If slug is not changed, just reload content
          if (response.data.slug === self.thirdParty.slug) {
            // Remove last item in breadcrumbs, because it will be added again on load content
            self.breadcrumbItems.pop();
            self.loadContent();
          } else {
            // If slug changed, reload state with new param
            self.$router.push({ name: 'contracts.view', params: { slug: response.data.slug }})
          }
          self.loadTypes();
          self.$toastr('success', self.$t('maintenance.third_parties.update.success'))
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      } else {
        this.error = this.$t('system.owners_error_message')
        this.buttonDisabled = false;
      }
    },
    loadCountries() {
      let self = this;
      piincHttp.get('countries').then(function(response) {
        self.countries = response.data
      }, function() {});
    },
    loadTypes(query) {
      this.typeInput = query ? query : '';
      let self = this;
      piincHttp.get('/third-parties/types', { params:
        {
          client: this.getClient.slug,
          keyword: query ? query : undefined
        }
      }).then(function(response) {
        self.types = response.data
        if (self.types && !self.types.length && !query) {
          self.types.push({type: self.$t('system.no_results'), $isDisabled: true})
        }
      }, function() {});
    },
    showCreateDOModal() {
      this.loadDataObjects();
      this.$refs['add-data-object'].show()
    },
    hideCreateDOModal() {
      this.error = ''
      this.selectedDO = [];
      this.$refs['add-data-object'].hide()
    },
    showDOConfirmDeleteModal(object) {
      this.itemToDelete = object
      this.$refs['delete-data-object'].show()
    },
    hideDOConfirmDeleteModal() {
      this.itemToDelete = {}
      this.$refs['delete-data-object'].hide()
    },
    DOFormSubmit(evt) {
      this.buttonDisabled = true;
      let formData = new FormData();
      // Keep the reference in a variable
      // to use it inside function called when promise returned
      let self = this;
      evt.preventDefault();

      if (this.selectedDO && this.selectedDO.id) {
        formData.append("data_object_id", this.selectedDO.id);
      }

      formData.append("third_party", this.$route.params.slug);

      piincHttp.post('/third-parties/' + this.$route.params.slug + '/data-objects', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function(response) {
        self.$toastr('success', self.$t('systems.data_objects.create.success'))
        self.hideCreateDOModal();
        self.buttonDisabled = false;
        self.loadDO();
      }, function(error) {
        if (error.status !== 200) {
          self.buttonDisabled = false;
          self.error = error.data.message;
          if (error.data.errors.data_object_id) {
            self.error += ' ' + error.data.errors.data_object_id[0]
          }
        }
      })
    },
    confirmDeleteDO(object) {
      let self = this;
      piincHttp.delete('third-parties/' + this.thirdParty.slug + '/data-objects/' + object.id).then(function() {
        self.hideDOConfirmDeleteModal();
        self.loadDO();
        // Trigger toastr message after modal close
        self.$toastr('success', self.$t('systems.data_objects.delete.success'))
      }, function() {});
    },
    loadDataObjects(query) {
      let self = this;
      piincHttp.get('/data-objects', { params:
        {
          client: this.getClient.slug,
          keyword: query ? query : undefined
        }
      }).then(function(response) {
        self.dataObjects = response.data.items
        if (self.dataObjects && !self.dataObjects.length && !query) {
          self.dataObjects.push({name: self.$t('system.no_results'), $isDisabled: true})
        }
      }, function() {});
    },
    // Table list
    loadDO(page, perPage) {

      if (this.getStorePerPage) {
        this.selectedPerPage = this.getStorePerPage;
      }

      let pageNumber = page ? page : 1;
      let perPageNumber = perPage ? perPage : this.selectedPerPage;
      let self = this;

      piincHttp.get('third-parties/' + this.$route.params.slug + '/data-objects', { params:
        {
          page: pageNumber,
          per_page: perPageNumber,
          sort: this.selectedSort !== '' ? this.selectedSort : undefined,
          order: this.selectedDirection !== '' ? this.selectedDirection : undefined,
          keyword: this.keywordValue !== '' ? this.keywordValue : undefined,
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.DOTableItems = response.data.items;
        self.DOLoading = false;
        // Set number of total items
        self.totalRows = response.data.total_count;
      }, function() {});

    },
    loadTags(query) {
      let self = this;
      piincHttp.get('tags', { params:
        {
          client: this.getClient.slug,
          keyword: query ? query : undefined
        }
      }).then(function(response) {
        self.tags = response.data
        if (self.tags && !self.tags.length && !query) {
          self.tags.push({name_translated: self.$t('system.no_results'), $isDisabled: true})
        }
        for (var i = 0; i < self.newTags.length; i++) {
          self.tags.push(self.newTags[i]);
        }
      }, function() {});
    },
    loadUsers(query) {
      let self = this;
      piincHttp.get('users', { params:
        {
          client: this.getClient.id,
          keyword: query ? query : undefined,
          per_page: 50
        }
      }).then(function(response) {
        self.users = response.data.items
        if (self.users && !self.users.length && !query) {
          self.users.push({name: self.$t('system.no_results'), $isDisabled: true})
        }
      }, function() {});
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        translatable: newTag,
        name_translated: newTag
      }
      this.selectedTags.push(tag)
      this.newTags.push(tag)
    },
    loadContent() {
      let self = this;
      piincHttp.get('third-parties/' + this.$route.params.slug, { params:
        {
          client: this.getClient.slug,
        }
      }).then(function(response) {
        self.thirdParty = response.data;
        self.loadTasks('update_basic_info');
        // Push Third party name in breadcrumb array
        self.breadcrumbItems.push({text: self.thirdParty.name, active: true});
      }, function(error) {
        if (error.status == 404) {
          self.show404 = true
        }
        if (error.status == 403) {
          self.show403 = true
        }
      });
    },
    loadTasks(code) {
      let self = this;
      if (this.getClient.projectModule) {
        piincHttp.get('projects/me/tasks', { params:
          {
            per_page: 1,
            status: 'pending',
            model_type: this.thirdParty.who_am_i,
            model_id: this.thirdParty.id,
            code: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myTasks.push(response.data.items[0])
          }
        }, function() {});
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('storeTabIndex', '');
    next()
  },
  created () {
    let self = this;
    if (this.getClient && this.getClient.slug) {
      this.loadContent()
      this.loadTypes()
      this.loadUsers()
      this.loadCountries()
      this.loadDataObjects()
    }
    this.loadDO();

    this.loadGuides('update_basic_info');
    this.loadGuides('update_contact');
    this.loadGuides('update_description');
    this.loadGuides('update_data');

    if (this.$route.params.tabIndex && this.$route.params.validation) {
      setTimeout(function(){
        self.tabIndex = self.$route.params.tabIndex
        if (self.tabIndex == 3) {
          self.riskTabClicked()
        }
      }, 500);
      setTimeout(function(){
        var element = document.getElementsByClassName(self.$route.params.validation);
        element[0].scrollIntoView(true);
        window.scrollBy(0, -100);
        element[0].classList.add("highlight-validation");
      }, 2000);
    }

  }
}
</script>
